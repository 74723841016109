export const getAllAppMessages = (host, token) =>
  fetch(`${host}/admin/app-messages`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      'ad-client-type': 'admin',
    },
  });
export const getAppMessage = (host, token, id) =>
  fetch(`${host}/admin/app-messages/${id}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      'ad-client-type': 'admin',
    },
  });

export const createAppMessage = (host, token, data) =>
  fetch(`${host}/admin/app-messages`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      'ad-client-type': 'admin',
    },
    body: data,
  });

export const updateAppMessage = (host, token, id, data) =>
  fetch(`${host}/admin/app-messages/${id}`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      'ad-client-type': 'admin',
    },
    body: data,
  });

export const deleteAppMessage = (host, token, id) =>
  fetch(`${host}/admin/app-messages/${id}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      'ad-client-type': 'admin',
    },
  });
