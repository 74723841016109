export const getHighLoadMode = (host, token) =>
  fetch(`${host}/admin/high-load-mode`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'ad-client-type': 'admin',
    },
  });

export const setHighLoadMode = (host, token, value) =>
  fetch(`${host}/admin/high-load-mode`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'ad-client-type': 'admin',
    },
    body: JSON.stringify(value),
  });

export const getSmsRestrictions = (host, token) =>
  fetch(`${host}/admin/sms-restrictions`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'ad-client-type': 'admin',
    },
  });

export const setSmsRestrictions = (host, token, body) =>
  fetch(`${host}/admin/sms-restrictions`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'ad-client-type': 'admin',
    },
    body: JSON.stringify(body),
  });
