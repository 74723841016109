export const getBoutiques = (
  host,
  token,
  page = 1,
  limit = 25,
  sort = 'status:asc',
  search = '',
) => {
  let link = `${host}/admin/shops?page=${page}&limit=${limit}`;

  if (search) {
    link += `&search=${search}`;
  }
  if (sort) {
    link += `&sort=${sort.replace(/(user|plan)\./, '')}`;
  }
  return fetch(link, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'ad-client-type': 'admin',
    },
  });
};

export const createBoutique = (host, token, body) => {
  return fetch(`${host}/admin/shops`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'ad-client-type': 'admin',
    },
    body: JSON.stringify(body),
  });
};

export const createBulkBoutique = (host, token, body) => {
  return fetch(`${host}/admin/shops/import`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'ad-client-type': 'admin',
    },
    body: JSON.stringify(body),
  });
};

export const getCurrentBoutique = (host, token, id) => {
  return fetch(`${host}/admin/shops/${id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'ad-client-type': 'admin',
    },
  });
};

export const changeDataBoutique = (host, token, id, body) => {
  return fetch(`${host}/admin/shops/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'ad-client-type': 'admin',
    },
    body: JSON.stringify(body),
  });
};
