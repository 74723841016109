export const getFeedBacks = (
  host,
  token,
  page = 1,
  limit = 25,
  sort = 'createdAt:desc',
  search = '',
  filter = '',
) => {
  let link = `${host}/admin/users/feedbacks?sort=${sort}&page=${page}&limit=${limit}`;
  if (search) {
    link += `&search=${search}`;
  }
  if (filter) {
    link += `&filter=${filter}`;
  }
  return fetch(link, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'ad-client-type': 'admin',
    },
  });
};

export const currentFeedback = (host, token, id, method, data = null) => {
  return fetch(`${host}/admin/users/feedbacks/${id}`, {
    method: `${method}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'ad-client-type': 'admin',
    },
    body: data,
  });
};
