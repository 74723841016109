export const getCoupons = (
  host,
  token,
  page = 1,
  limit = 25,
  sort = 'status:asc',
  search = '',
  filter,
) => {
  sort = sort.replace('domain:', 'ratingColor:');
  let link = `${host}/admin/domains/offers?`;
  if (page) {
    link += `page=${page}`;
  }
  if (limit) {
    link += `&limit=${limit}`;
  }

  if (sort) {
    link += `&sort=${sort}`;
  }

  if (search) {
    link += `&search=${search}`;
  }

  if (filter) {
    link += `&filter=${filter}`;
  }
  return fetch(link, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'ad-client-type': 'admin',
    },
  });
};

export const currentCoupon = (host, token, id, method, data = null) => {
  return fetch(`${host}/admin/domains/offers/${id}`, {
    method: `${method}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'ad-client-type': 'admin',
    },
    body: data,
  });
};

export const postSaveCoupon = (host, token, data) => {
  return fetch(`${host}/admin/domains/offers`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'ad-client-type': 'admin',
    },
    body: data,
  });
};
export const postImportCSV = (host, token, data, signal) => {
  return fetch(`${host}/admin/domains/offers/import`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'ad-client-type': 'admin',
    },
    body: JSON.stringify(data),
    ...(signal && { signal }),
  });
};

export const getStatusImport = (host, token, signal) => {
  return fetch(`${host}/admin/domains/offers/import/status`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'ad-client-type': 'admin',
    },
    signal,
  });
};

export const getOffersFile = (host, token, signal) => {
  return fetch(`${host}/admin/domains/offers/export`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'ad-client-type': 'admin',
    },
  });
};
