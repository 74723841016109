export const getCategory = (host, token, ...other) => {
  const { data, sort, page, limit, search } = other[0];
  return fetch(
    `${host}/admin/domains/categories?sort=${sort}${!!page ? `&page=${page}` : ''}${!!limit ? `&limit=${limit}` : ''}${!!search ? `&search=${search}` : ''}${data && data.filter ? `&filter=${data.filter}` : ''}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        'ad-client-type': 'admin',
      },
    },
  );
};
export const getCategoryForDomains = (host, token, limit) => {
  let link = `${host}/admin/domains/categories`;
  if (limit) {
    link += `?limit=${limit}`;
  }
  return fetch(link, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'ad-client-type': 'admin',
    },
  });
};

export const addCategory = (host, token, data) =>
  fetch(`${host}/admin/domains/categories`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'ad-client-type': 'admin',
    },
    body: JSON.stringify(data),
  });
export const updateCategory = (host, token, data) => {
  const { id, ...body } = data;
  return fetch(`${host}/admin/domains/categories/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'ad-client-type': 'admin',
    },
    body: JSON.stringify(body),
  });
};
export const syncCategory = (host, token, data) =>
  fetch(`${host}/admin/domains/categories/${data.id}/sync`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'ad-client-type': 'admin',
    },
  });

export const deleteCategory = (host, token, data) =>
  fetch(`${host}/admin/domains/categories/${data.id}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'ad-client-type': 'admin',
    },
  });

export const getCategoryData = (host, token, data) =>
  fetch(`${host}/admin/domains/categories/${data?.id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'ad-client-type': 'admin',
    },
  });
