export const getYoutubers = (host, token, page = 1, limit = 100) => {
  let link = `${host}/admin/youtubers?page=${page}&limit=${limit}`;
  return fetch(link, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'ad-client-type': 'admin',
    },
  });
};

export const addYoutubers = (host, token, name) => {
  const reqBody = { name };

  let link = `${host}/admin/youtubers`;
  return fetch(link, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'ad-client-type': 'admin',
    },
    body: JSON.stringify(reqBody),
  });
};

export const deleteYoutubers = (host, token, id) => {
  let link = `${host}/admin/youtubers/${id}`;
  return fetch(link, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'ad-client-type': 'admin',
    },
  });
};
