export const favoriteLatest = (host, token) =>
  fetch(`${host}/eden/admin/favorite/latest`, {
    headers: {
      Authorization: `Bearer ${token}`,
      'ad-client-type': 'admin',
    },
  });

export const favoriteIdProduct = (host, token, id) =>
  fetch(`${host}/eden/admin/favorite/${id}/search`, {
    headers: {
      Authorization: `Bearer ${token}`,
      'ad-client-type': 'admin',
    },
  });

export const favoriteSimilar = (host, token, id, body) =>
  fetch(`${host}/eden/admin/favorite/${id}/similar`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'ad-client-type': 'admin',
    },
    body: JSON.stringify(body),
  });

export const favoritePrimary = (host, token, id, body) =>
  fetch(`${host}/eden/admin/favorite/${id}/primary`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'ad-client-type': 'admin',
    },
    body: JSON.stringify(body),
  });

export const sendCouponsForMainProduct = (host, token, id, body) =>
  fetch(`${host}/eden/admin/favorite/${id}/primary/discount`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'ad-client-type': 'admin',
    },
    body: JSON.stringify(body),
  });

export const getOffersList = (host, token, domain) =>
  fetch(
    `${host}/admin/domains/offers?page=1&limit=50&filter=active&search=${domain}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'ad-client-type': 'admin',
      },
    },
  );

export const removeOfferById = (host, token, id) =>
  fetch(`${host}/admin/domains/offers/${id}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
      'ad-client-type': 'admin',
    },
  });

export const decodeUrl = (host, token, url, signal) =>
  fetch(`${host}/domains/decode`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'ad-client-type': 'admin',
    },
    body: JSON.stringify({ url }),
    signal,
  });

export const removeFavoriteMainProduct = (host, token, id) =>
  fetch(`${host}/eden/admin/favorite/${id}/discard`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
      'ad-client-type': 'admin',
    },
  });

export const researchProduct = (host, token, id) =>
  fetch(`${host}/admin/products/${id}/research`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-type': 'Application/json',
      'ad-client-type': 'admin',
    },
  });
